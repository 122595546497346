import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
// import Img from 'gatsby-image/withIEPolyfill'
// import { css } from '@emotion/core'

import Layout from 'templates/layout'
import SEO from 'components/seo'
import Modal from 'components/modal'

export default ({
  data: {
    markdownRemark: {
      frontmatter: { url, image, description, name },
    },
  },
}) => (
  <ModalRoutingContext>
    {({ modal, closeTo }) => (
      <Fragment>
        {modal ? (
          <Modal
            url={url}
            closeTo={closeTo}
            image={image}
            description={description}
          />
        ) : (
          <Layout>
            <SEO title={`取り扱いブランド ${name}`} description={description} />
            <Modal
              url={url}
              closeTo="/brands"
              image={image}
              description={description}
            />
          </Layout>
        )}
      </Fragment>
    )}
  </ModalRoutingContext>
)

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        name
        url
        image {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description
      }
    }
  }
`
