import React from 'react'
// import { css } from '@emotion/core'

import Button from 'components/atoms/button'
import Img from 'gatsby-image/withIEPolyfill'

export default ({ closeTo, url, image, description }) => (
  <div className="m-modal">
    <a
      className="m-modal__logo"
      target="_blank"
      rel="noopener noreferrer"
      href={url}
    >
      <Img fluid={image.childImageSharp.fluid} />
    </a>
    <div className="m-modal__text">{description}</div>
    <Button internal={closeTo} thin>
      close
    </Button>
  </div>
)
